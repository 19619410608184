import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Checkbox, Button } from "antd";
import { map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/auth/reducer";
import "../assets/style/Login.scss"

const Login = () => {
  const { isLogin, errorCode, error } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
    let data = localStorage.getItem("autoFill");
    if (!!data) {
      data = JSON.parse(data);
      map(data, (value, key) => {
        setValue(key, value);
      });
    }
    // if (errorCode === "x001") {
    //   navigate("/verify/send");
    // } else 
    if (errorCode === "" && isLogin) {
      navigate("/");
    }
  }, [isLogin, errorCode, setValue, navigate]);

  const handleLogin = async (e) => {
    setLoading(true);
    const headers = new Headers();
    headers.append('Cache-Control', 'no-cache');
      fetch('https://www.google.com', {
        mode: 'no-cors'
      })
      .then((response) => {
        if (response.status != 200) {
          const { email, password, remember, autoFill } = getValues();
          try {
            dispatch(login({ email, password, remember, autoFill }));
          } catch (error) {
            console.log("error", error);
          }
        }
      }, (err) => {
        alert('You are offline!')
    setLoading(false);
  })}

  const handleSignUp = () => {
    navigate("/register");
  };
  const handleInput = (e) => {
    if (e.which === 32) {
      return false;
    }
  }

  return (
    <div className="col-md-12 login-container">
      <div className="login-pg">
        <label className="app-title">CASINO UNITY</label>
        <label className="page-title">ログイン</label>

        <form onSubmit={handleSubmit(handleLogin)} autoComplete="off">
          <div className="form-group form-input">
            <label className="title" htmlFor="email">メールアドレス</label>
            <input
              type="text"
              className={`form-control ${!!errors.email ? "border_input_error" : ""
                }`}
              name="email"
              {...register("email", { required: true })}
              onKeyPress={handleInput}
            />
            {errors.email && (
              <span className="error-feedback">
                ※有効なメールアドレスを入力してください。
              </span>
            )}
          </div>

          <div className="form-group form-input">
            <label className="title" htmlFor="password">パスワード</label>
            <input
              type="password"
              className={`form-control ${!!errors.password ? "border_input_error" : ""
                }`}
              name="password"
              {...register("password", { required: true })}
            />
            {errors.password && (
              <span className="error-feedback">
                ※有効なパスワードを入力してください。
              </span>
            )}
          </div>

          <div className="form-group">
            <Controller
              name="autoFill"
              control={control}
              defaultValue={false}
              value={watch("autoFill")}
              render={({ field }) => {
                return (
                  <Checkbox
                    className="check-box"
                    onChange={field.onChange}
                    checked={!!field.value}
                  >
                    入力内容を保存する
                  </Checkbox>
                );
              }}
            ></Controller>
          </div>

          <div className="form-group">
            <Controller
              name="remember"
              control={control}
              defaultValue={false}
              value={watch("remember")}
              render={({ field }) => {
                return (
                  <Checkbox
                    className="check-box"
                    onChange={field.onChange}
                    checked={!!field.value}
                  >
                    次回から自動でログインする
                  </Checkbox>
                );
              }}
            ></Controller>
          </div>
          {error.login && <span className="error-feedback">{error.login}</span>}
          <div className="form-group">
            <Button
              className="btn-login"
              type="submit"
              disabled={loading}
              onClick={handleSubmit(handleLogin)}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>ログイン</span>
            </Button>
          </div>
        </form>
      </div>
      <hr style={{ background: '#eabbaa', margin: '28px 0' }} />
      <div className="btn_signUp">
        <button
          className="btn-signUp"
          type="submit"
          disabled={loading}
          onClick={handleSignUp}
        >
          <span>新規登録</span>
        </button>
      </div>
    </div>
  );
};

export default Login;
