import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "./index.css";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import AuthService from "./services/auth.service";
import AppRoutes from "./routes";
import EventBus from "./common/EventBus";
import ScrollToTop from "./components/ScrollToTop";

const App = ({ store }) => {
  useEffect(() => {
    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
  };

  return (
    <Provider store={store}>
      <ScrollToTop>
        <AppRoutes />
      </ScrollToTop>
    </Provider>
  );
};

export default App;
