export default {
  login: {
    invalidCredential: '入力されたメールアドレスかパスワードは正しくありません。',
  },
  common: {
    unknownError: '不明なエラー',
  },
  verify: {
    sendMailSuccess: '登録されたメールアドレスに認証用のメールが届きました。メールをご確認ください。',
  }
}
