import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogoutModal: false,
};
export const layout = createSlice({
  name: "layout",
  initialState,
  reducers: {
    updateIsLogoutModal: (state) => {
      state.isLogoutModal = !state.isLogoutModal;
    },
    closeLogoutModal: (state) => {
      state.isLogoutModal = false;
    },
  },
});

export const { updateIsLogoutModal } = layout.actions;

export const { closeLogoutModal } = layout.actions;

export const selectIsLogoutModal = (state) => state.layout.isLogoutModal;

export default layout.reducer;
