import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../assets/style/authenEmail.scss";
import authService from "../services/auth.service";

function AuthenticationEmail() {
  const [isError, setIsError] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false)
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.id) {
      navigate('/login')
    }
  }, [navigate, user])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const sendEmailHandler = async () => {
    if (user.email) {
      try {
        setDisableBtn(true)
        await authService.sendVerifyEmail(user.id)
        setIsError(false);
        alert('メールが受信トレイに送信されました。チェックして確認してください')
      } catch (error) {
        setDisableBtn(false)
        setIsError(true);
      }
    }
  };

  return (
    <div className="authentication_email">
      <div className="authentication_email_title">
        <p className="authentication_email_title_text">CASINO UNITY</p>
      </div>
      <div className="authentication_email_warning">
        <div className="authentication_email_warning_icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-exclamation-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
          </svg>
        </div>
        <span className="authentication_email_warning_text">
          <p>まだ登録は完了しておりません。</p>
          <p>
            以下のボタンを押して登録したメールアドレスへ 認証メールを送ります。
          </p>
        </span>
      </div>
      <div className="authentication_email_btn">
        <button className={disableBtn ? 'btn_disable' : ''} onClick={sendEmailHandler} disabled={disableBtn}>認証メール送信</button>
      </div>
      <div className="authentication_email_notice">
        <label className="authentication_email_notice_text">
          認証メールに記載されているリンクから認証を有効化してください。
        </label>
      </div>
    </div>
  );
}

export default AuthenticationEmail;
