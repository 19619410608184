import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";
import "../assets/style/Home.scss";
import { useSelector } from "react-redux";
import CommonLoader from "../components/loader";

const Home = () => {
  const navigate = useNavigate()

  const [content, setContent] = useState({});

  const user = useSelector(state => state.auth.user)

  const [isLoading, setIsLoading] = useState(true);

  const formatConcurrency = (concurrency) => {
    return concurrency.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    authService.getProfile().then(
      (response) => {
        setContent(response.data);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);

  const handleStalkingHistory = () => {
    navigate('/staking')
  }
  const handleNavigateWallet = () => {
    navigate('/staken-amount')
  }
  const handleNavigateAffiliate = () => {
    navigate('/affiliate')
  }
  
  return (
    <>
      {isLoading && <div className="loading-container"><CommonLoader isLoading={isLoading} /></div>}
      {!isLoading &&
        <div className="home__container">
          <label className="information__text">Information</label>
          <textarea
            type="text-area"
            className="home__input"
            disabled
            value={"This service is a beta version."}
          />
          <div className="information__user">
            <span className="information__user_name row">
              <p className="user_name col-3">Name</p>
              <label className="label_user_name col-9">{user?.name}</label>
            </span>
            <span className="information__user_id row">
              <p className="user_id col-3">ID</p>
              <label className="label_user_id col-9">{user?.email}</label>
            </span>
            <span className="information__user_balance row">
              <p className="user_balance col-3">Balance</p>
              <span className="col-9">
                <label className="label_user_balance">
                  {formatConcurrency(content?.balances?.total_balance || 0)}
                </label>
                <label className="label_user_balance_type">USDT</label>
              </span>
            </span>
          </div>
          <div className="information__coin">
            <div className="row f-flex ">
              <div className="col-4 col-name">
                <div className="circle__bj_coin coin_circle m-auto">
                  <span>BJ</span>
                </div>
                <label>{formatConcurrency(content?.balances?.game_balance?.[0] || 0)}</label>
              </div>
              <div className="col-4 col-name">
                <div className="circle__rl_coin coin_circle m-auto">
                  <span>RL</span>
                </div>
                <label>{formatConcurrency(content?.balances?.game_balance?.[1] || 0)}</label>
              </div>
              <div className="col-4 col-name">
                <div className="circle__bc_coin coin_circle m-auto">
                  <span>BC</span>
                </div>
                <label>{formatConcurrency(content?.balances?.game_balance?.[2] || 0)}</label>
              </div>
            </div>
          </div>
          <div className="information_btn row">
            <button className="btn_special_bonus" onClick={handleNavigateAffiliate}>
              <img src="/image/dolla.svg" alt="" />
              <p>Special Bonus</p>
            </button>
            <button className="btn_staking_history" onClick={handleStalkingHistory}>
              <img src="/image/time_back.svg" alt="" />
              <p>Staking History</p>
            </button>
            <button className="btn_latest_staking_reward" onClick={handleNavigateWallet}>
              <img src="/image/money_bag.svg" alt="" />
              <p>Latest Staking Reward</p>
            </button>
          </div>
        </div>
      }
    </>
  );
};

export default Home;
