import React, { useState, useEffect, useRef } from "react";
import walletService from "../services/wallet.service";
import "../assets/style/StakenAmountDetail.scss";
import { Button } from "antd";
import { Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import CommonLoader from "../components/loader";

const StakenAmountDetail = () => {
  const navigate = useNavigate()
  const [content, setContent] = useState([]);
  const [withdrawAddress, setWithdrawAddress] = useState("");
  //hard fix cause by design
  const [stakingType, setStakingType] = useState(0);
  const [isModal, setIsModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const modalRef = useRef(null);
  const modal_checkbox = useRef(null);
  const modal_input = useRef(null);
  const img = ["/image/down.png", "", "/image/up.png", "/image/g.png"];
  const text = ["入金", "", "出金", "報酬"];
  const classType = ["down", "", "up", "g"];
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(true);

  let stakingAmountStart = 0;
  const formatConcurrency = (concurrency) => {
    return Number(concurrency)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const addressChangeHandler = (e) => {
    var TCode = modal_input.current.value;
    if ((TCode === '' || (/^[a-zA-Z\s]+$/.test(TCode))) && TCode.length <= 200) {
      setWithdrawAddress(e.target.value.replace(/^\s+/gm, ''));
    }
    else {
      return false
    }
  };

  useEffect(() => {
    setIsLoading(true);
    walletService.getStakingDetails(stakingType).then(
      (response) => {
        setContent(response.data);
        window.scrollTo(0, 0);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [stakingType]);

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("click", handleClickOutside, true);
    return () => window.removeEventListener("click", handleBtnCloseModal, true);
  }, []);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setIsModal(false);
    }
  };

  const handleIsShowModal = () => {
    walletService.getStakingDetails(stakingType).then(
      (response) => {
        setIsModal(true)
      },
      (error) => {
        alert('You are offline')
      }
    );
  };

  const handleBtnCloseModal = (e) => {
    e.stopPropagation();
    setIsModal(false);
  };

  const handleBtnApply = () => {
    if (modal_checkbox.current.state.checked === true &&
      modal_input.current.value != "") {
      // need call request on chain before call api
      let data = {
        user_id: user.id,
        deposit_amount: Number(
          content?.reduce(function (total, value) {
            return (
              total +
              (value?.staking_log_type === 1 ? value?.staking_log_amount : 0)
            );
          }, 0) || 0
        ),
        deposit_type: stakingType, //0:USDT, 1:BUSD, 3:USDC
        deposit_date: moment().format("YYYY-MM-DD h:mm:ss"),
        verification_status: 0, // 0: Not reflected, 1: Reflected, 2: error
        withdraw_address: withdrawAddress,
      };
      setWithdrawAddress('')
      walletService.createWithdrawRequest(data);
      setIsConfirmModal(true);
      setIsModal(false);
    }
    else if (document.querySelector('.modal_input_address').value.length > 200) {
      return false
    }
  };

  const handleShowPayment = () => {
    walletService.getStakingDetails(stakingType).then(
      () => {
        navigate('/staking', { state: { showUp: true, type: stakingType } });
      },
      () => {
        alert('You are offline')
      }
    );
  }

  const handleBtnConfirm = () => {
    setIsConfirmModal(false);
  };

  return (
    <div className="container staken-amount-container-body">
      <div className="button-group">
        <div className="row-col-name d-flex">
          <div
            className={`col-name ${stakingType == 0 ? "active col-staking" : ""
              } d-flex`}
            onClick={(e) => { setStakingType(0); setWithdrawAddress('') }}
          >
            BJ
          </div>
          <hr />
          <div
            className={`col-name ${stakingType == 1 ? "active col-staking" : ""
              } d-flex`}
            onClick={(e) => { setStakingType(1); setWithdrawAddress('') }}
          >
            RL
          </div>
          <hr />
          <div
            className={`col-name ${stakingType == 2 ? "active col-staking" : ""
              } d-flex`}
            onClick={(e) => { setStakingType(2); setWithdrawAddress('') }}
          >
            BC
          </div>
        </div>
      </div>
      {isLoading && <div className="loading-container"><CommonLoader isLoading={isLoading} /></div>}
      {!isLoading &&
        <>
          <div className="home__container staken-amount-container">
            <div className="medium-amount-staking amount_title">
              <label className="information__text title">ステーキング中金額</label>
              <label className="information__text">
                {formatConcurrency(
                  content?.[0]?.user?.balances?.game_balance?.[stakingType] || 0
                )}{" "}
                [USDT]
              </label>
            </div>
            <div className="amount-waiting-withdrawal amount_title">
              <label className="information__text title">出金待機中金額</label>
              <label className="information__text">
                {formatConcurrency(
                  content?.reduce(function (total, value) {
                    return (
                      total +
                      (value?.staking_log_type === 1 ? value?.staking_log_amount : 0)
                    );
                  }, 0) || 0
                )}{" "}
                [USDT]
              </label>
            </div>
            <div className="information__coin staken-information">
              <table className="table table-staken-history">
                <thead>
                  <tr>
                    <th scope="col col-4">履歴</th>
                    <th scope="col col-4">金額</th>
                    <th scope="col col-4">ステーキング額</th>
                  </tr>
                </thead>
                <tbody>
                  {content
                    .filter((value) => value.staking_log_type !== 1)
                    .map((value, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <div className="history d-flex justify-content-center">
                              <div>
                                <img src={img[value.staking_log_type]} alt="" />
                              </div>
                              <div className="content">
                                <label>{text[value.staking_log_type]}</label>
                                <label className="date-time">
                                  {moment(value.staking_log_date).format(
                                    "YYYY-MM-DD h:mm"
                                  )}
                                </label>
                              </div>
                            </div>
                          </td>
                          <td
                            className={`amount-money money-${classType[value.staking_log_type]
                              }`}
                          >
                            {formatConcurrency(value.staking_log_amount)}
                          </td>
                          <td>{formatConcurrency(value.total_staking)}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="bottom-group">
            <div className="row-col-name d-flex">
              <div className="col-name col-staking d-flex">
                <Button className="button btn-login"
                  onClick={handleShowPayment}
                >
                  <span>入金</span>
                </Button>
              </div>
              <div className="col-name col-bonus d-flex">
                <Button className="button btn-login" onClick={handleIsShowModal}>
                  <span>出金申請</span>
                </Button>
              </div>
            </div>
          </div>
        </>
      }
      {isModal && (
        <div className="modal_background">
          <div className="modal_container" ref={modalRef}>
            <div className="modal_pay">
              <div className="modal_caution">
                <div className="modal_caution_title">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z" />
                  </svg>
                  <label>Caution</label>
                </div>
                <div className="modal_caution_content">
                  <ul>
                    <li>
                      <p>
                        出金申請をした場合、次回のステーキングの更新は行われません
                      </p>
                    </li>
                    <li>
                      <p>
                        出金は次回のステーキング更新タイミング後の1週間が目安です
                      </p>
                    </li>
                    <li>
                      <p>出金先アドレスはTron (TRC20) のUSDTでお願い致します</p>
                    </li>
                    <li>
                      <p>
                        出金先アドレスにお間違えのある場合、出金ができません
                      </p>
                    </li>
                    <li>
                      <p>
                        一度出金申請をした場合、出金をキャンセルすることはできません
                      </p>
                    </li>
                    <li>
                      <p>
                        出金はステーキング中のゲーム別での金額の全額が対象となります
                      </p>
                    </li>
                  </ul>
                </div>

              </div>
              <div className="modal_checkbox_accept">
                <Checkbox className="modal_checkbox" ref={modal_checkbox}>
                  上記の注意事項に目を通し、了承した
                </Checkbox>
              </div>
              <div className="modal_withdrawl_address">
                <label>出金先ウォレットアドレス</label>
                <input
                  type="text"
                  value={withdrawAddress}
                  className="modal_input modal_input_address"
                  onChange={addressChangeHandler}
                  ref={modal_input}
                />
              </div>
              <div className="modal_address">
                <label className="title_network">ネットワーク</label>
                <label className="network">Tron (TRC20)</label>
              </div>
              <div className="modal_button">
                <button className="btn_apply" onClick={handleBtnApply}>
                  出金申請実行
                </button>
                <button className="btn_cancel" onClick={handleBtnCloseModal}>
                  キャンセル
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isConfirmModal && (
        <div className="confirm_modal">
          <div className="confirm_modal_content">
            <label>出金申請を実行しました。</label>
            <label>メールをご確認ください。</label>
            <button className="btn_confirm" onClick={handleBtnConfirm}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


export default StakenAmountDetail;
