import request from "../utils/request";
import axios from "../utils/request";

const downloadDepositReport = () => {
  return request.get("/download/0");
};

const downloadWithdrawal = () => {
  return request.get("/download/1");
};

const uploadData = (file, type) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = process.env.REACT_APP_BASE_URL;
  return axios({
    method: "post",
    url: `${url}/upload/${type}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
};

const upDownLoadService = {
  downloadDepositReport,
  downloadWithdrawal,
  uploadData,
};
export default upDownLoadService;
