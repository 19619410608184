import React, { useState, useRef, useEffect } from "react";
import get from "lodash/get";
import { fileValidator, preventBrowserDefaults } from "./drag-drop";
import upDownLoadService from "../services/upDownLoad.service";
import WalletService from "../services/wallet.service";


const DragAndDrop = ({ children, config, closeModalFnc, typeFile, closeReportMail, isShowModalReport }) => {
  let [dragOverlay, setDragOverlay] = useState(false);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [isShowModalReportMail, setIsShowModalReportMail] = useState(true)
  const [messageError, setMessageError] = useState(false)
  let dragCounter = useRef(0);

  const handleDrag = e => {
    preventBrowserDefaults(e);
  };
  const handleDragIn = e => {
    preventBrowserDefaults(e);
    dragCounter.current++;
    if (get(e, "dataTransfer.items.length") > 0) {
      setDragOverlay(true);
    }
  };
  const handleDragOut = e => {
    preventBrowserDefaults(e);
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setDragOverlay(false);
    }
  };
  const handleDrop = e => {
    const files = get(e, "dataTransfer.files");
    preventBrowserDefaults(e);
    setDragOverlay(false);
    setError(false);
    setMessageError(false)
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
      }
      return false;
    }
    fileReader(files[0]);
  };

  const fileReader = files => {
    setData(files)
  };

  const dragOverlayClass = dragOverlay ? "overlay" : "";

  const handleCloseModal = () => {
    closeModalFnc()
  }

  const handleUploadFile = () => {
    if (data) {
      setError(false)
      upDownLoadService.uploadData(data, typeFile)
        .then(response => {
          setIsShowModalReportMail(true)
          closeModalFnc()
          isShowModalReport(true)
          setData('')
        })
        .catch(err => {
          setMessageError(err.response?.data?.errors?.file[0] || err.response?.data);
        })
    } else {
      setError('Please input file.')
    }

    WalletService.getStakingDetails(0).then(
      (response) => {},
      (error) => {
        alert('You are offline')
      })
  }

  let input_file = document.querySelector('.model_input_type_file')
  const handleModalClick = (e) => {
    if (!data) {
      input_file.click()
    }
  }
  const inputChange = (e) => {
    const files = e.target.files
    setDragOverlay(false);
    setError(false);
    dragCounter.current = 0;
    const { isValidFile, errVal } = fileValidator(files, config);
    if (!isValidFile) {
      if (errVal) {
        setError(errVal);
      }
      return false;
    }
    fileReader(files[0]);
  }

  useEffect(() => {
    setIsShowModalReportMail(closeReportMail)
  }, [closeReportMail])
  return (
    <div>
      {isShowModalReportMail &&
        <div className="modal_mail_report">
          <p>Upload success!</p>
          <div className="modal_success_mail">
            <div className="modal_success_mail_content">
              <p>An email will be sent to you when the import is finished,</p>
              <p>check your mail to see the results.</p>
            </div>
            <div className="button_group_modal">
              <button onClick={() => setIsShowModalReportMail(false)}>OK</button>
            </div>
          </div>
        </div>
      }
      <div
        className={`drag-container ${dragOverlayClass}`}
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {children}
        <div className="modal_upload_file">
          <input type="file" className="model_input_type_file" hidden onChange={inputChange} />
          <div className="modal_upload_file_content">
            <div className="title">
              <h1>Upload File</h1>
            </div>
            <div className="main_content drag-area" onClick={handleModalClick}>
              {data ?
                <div className="file_infos">
                  <div className="file_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256z" />
                    </svg>
                  </div>
                  <div className="file_detail">
                    <p className="file_name">{`Name: ${data.name}`}</p>
                    <p className="file_type">{`Type: ${data.type}`}</p>
                    <p className="file_size">{`Size: ${data.size} kb`}</p>
                  </div>
                </div>
                :
                <>
                  <div className="main_content_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256z" />
                    </svg>
                  </div>
                  <div className="main_content_text">
                    <p>Drag and drop files here</p>
                    <p>State machine based on file upload</p>
                  </div>

                  {error && <p className="error">{error}</p>}
                </>
              }
              <div className="button-wrapper">
                {data && <button onClick={() => setData(false)}>Remove</button>}
              </div>
              {messageError && <p className="errorApi">{messageError}</p>}
            </div>
            <div className="main_content_button">
              <button className="btn_cancel" onClick={handleCloseModal}>Cancel</button>
              <button className="btn_apply" onClick={handleUploadFile}>Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DragAndDrop;
