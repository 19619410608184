import {React,useEffect} from "react";
import { Link } from "react-router-dom";

import "../assets/style/error_404.scss";

const Error404 = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className="board center">
      <p id="error">
        error
      </p>
      <p id="code">
        404
      </p>
      <div align="center">
        <Link className="btn-back" to="/">Back to homepage</Link>
      </div>
    </div>
  )
}

export default Error404
