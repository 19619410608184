import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import service from "../../services/auth.service";
import message from "../../constants/message";

const initialState = {
  user: null,
  isLogin: false,
  isLoading: false,
  errorCode: "",
  error: {},
};
export const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isLogin = true;
        state.isLoading = false;
        state.errorCode = action.payload.code || "";
        if (action.payload.code === "x001") {
          state.isLogin = false;
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error.login = message.login.invalidCredential;
      })
      .addCase(refresh.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.user = action.payload.user;
        state.isLogin = true;
        state.isLoading = false;
      })
      .addCase(refresh.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLogin = true;
        state.user = action.payload.data.user;
        state.errorCode = action.payload.data.code || "";
        if (action.payload.data.code === "x001") {
          state.isLogin = false;
        }
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error.register = message.common.unknownError;
      })
      .addCase(logout.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = null;
        state.isLogin = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error.logout = message.common.unknownError;
      });
  },
});
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password, remember, autoFill }) => {
    service.setAutoComplete({
      email,
      password,
      autoFill,
    });

    return service.login(email, password, remember);
  }
);

export const refresh = createAsyncThunk("auth/refresh", async () => {
  return service.refresh();
});

export const register = createAsyncThunk("auth/register", async (data) => {
  return service.register(data);
});

export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  return service.logout();
});

export const selectUser = (state) => state.user;

export default auth.reducer;
