import React, { useState } from "react";
import DragAndDrop from "./drag-and-drop";

const config = {
  allowedFileFormats: ["text/csv"],
  fileSizeMBLimit: 10,
  filesLimit: 1
};

const FILE_UPLOADER_STATE = {
  INIT: "INIT",
  PROCESSING: "PROCESSING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

const FileUploader = ({ closeModalFnc, typeFile, closeReportMail, isShowModalReport }) => {
  const [loaderState, setLoaderState] = useState(FILE_UPLOADER_STATE.INIT);
  return (
    <>
      {loaderState === FILE_UPLOADER_STATE.INIT && (
        <DragAndDrop config={config} closeModalFnc={closeModalFnc} typeFile={typeFile} isShowModalReport={isShowModalReport} closeReportMail={closeReportMail}/>
      )}
      {loaderState === FILE_UPLOADER_STATE.PROCESSING && (
        <div className="drag-container">Processing...</div>
      )}
      {loaderState === FILE_UPLOADER_STATE.SUCCESS && (
        <div className="drag-container">File Upload done!</div>
      )}
      {loaderState === FILE_UPLOADER_STATE.FAILURE && (
        <div className="drag-container">
          File Upload failed. Please try again!
        </div>
      )}
    </>
  );
};

export default FileUploader;
