import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLogoutModal, closeLogoutModal } from "../store/layout/reducer";

const Header = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth?.user);
  const navigate = useNavigate();
  const isLogin = useSelector((state) => state.auth.isLogin);
  const [visible, setVisible] = useState(false);
  
  const showDrawer = () => {
    dispatch(closeLogoutModal());
    setVisible(!visible);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    setVisible(false);
    dispatch(updateIsLogoutModal());
  };

  const redirectToHome = () => {
    showDrawer();
    navigate("/");
  };

  const redirectToWallet = () => {
    showDrawer();
    navigate("/wallet");
  };


  const redirectToStaking = () => {
    showDrawer();
    navigate("/staking");
  };

  const redirectToAffiliate = () => {
    showDrawer();
    navigate("/affiliate");
  };

  const redirectToUpDownLoad = () => {
    showDrawer();
    navigate("/update-data");
  }
  return (
    <nav className="navbar navbar-header navbar-expand">
      {isLogin && (
        <>
          {!visible ? (
            <svg style={{ cursor: 'pointer' }}
              onClick={showDrawer}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="22.5 22.5 22 16"
            >
              <g data-name="ハンバーガーアイコン">
                <path
                  d="M22.5 38.5h22"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="3"
                  stroke="#00a180"
                  fill="transparent"
                  data-name="線 3"
                />
                <path
                  d="M22.5 30.5h22"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="3"
                  stroke="#00a180"
                  fill="transparent"
                  data-name="線 2"
                />
                <path
                  d="M22.5 22.5h22"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="3"
                  stroke="#00a180"
                  fill="transparent"
                  data-name="線 1"
                />
              </g>
            </svg>
          ) : (
            <svg
              style={{ cursor: 'pointer' }}
              onClick={showDrawer}
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="22.5 22.559 15.882 15.882"
            >
              <g data-name="閉じる">
                <path
                  d="m22.826 38.441 15.23-15.882"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="3"
                  stroke="#00a180"
                  fill="transparent"
                  data-name="線 3"
                />
                <path
                  d="m22.826 22.559 15.23 15.882"
                  strokeLinejoin="round"
                  strokeLinecap="round"
                  strokeWidth="3"
                  stroke="#00a180"
                  fill="transparent"
                  data-name="線 1"
                />
              </g>
            </svg>
          )}
          <Drawer
            placement={"left"}
            closable={false}
            onClose={onClose}
            visible={visible}
            key={"left"}
          >
            <span className="side-bar" onClick={redirectToHome}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="16 40 21 21"
              >
                <path
                  d="M16 51.667h9.333V40H16v11.667ZM16 61h9.333v-7H16v7Zm11.667 0H37V49.333h-9.333V61Zm0-21v7H37v-7h-9.333Z"
                  fill="#fff"
                  file-rule="evenodd"
                  data-name="Icon material-dashboard"
                />
              </svg>
              トップ
            </span>
            <span className="side-bar" onClick={redirectToWallet}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M448 32C465.7 32 480 46.33 480 64C480 81.67 465.7 96 448 96H80C71.16 96 64 103.2 64 112C64 120.8 71.16 128 80 128H448C483.3 128 512 156.7 512 192V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H448zM416 336C433.7 336 448 321.7 448 304C448 286.3 433.7 272 416 272C398.3 272 384 286.3 384 304C384 321.7 398.3 336 416 336z" />
              </svg>
              ウォレット
            </span>
            <span className="side-bar" onClick={redirectToStaking}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="16.5 176 20 21"
              >
                <g data-name="グループ 13">
                  <path
                    d="M30.515 193.498h-13.48c-.295 0-.535-.268-.535-.6v-2.797c0-.33.24-.599.536-.599h13.48v-2.3c0-1.069 1.153-1.604 1.828-.848l3.842 4.298a1.302 1.302 0 0 1 0 1.696l-3.842 4.298c-.675.756-1.829.22-1.829-.847v-2.3ZM22.485 183.498h13.48c.295 0 .535-.268.535-.6v-2.797c0-.33-.24-.599-.536-.599h-13.48v-2.3c0-1.069-1.153-1.604-1.828-.848l-3.842 4.298a1.302 1.302 0 0 0 0 1.696l3.842 4.298c.675.756 1.829.22 1.829-.847v-2.3Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="Icon awesome-long-arrow-alt-left"
                  />
                </g>
              </svg>
              ステーキング
            </span>
            <span className="side-bar" onClick={redirectToAffiliate}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="25"
                viewBox="16 303 20 25"
              >
                <g data-name="ドル袋の無料アイコン素材">
                  <path
                    d="M27.803 307.678a.61.61 0 0 0-.155-.02H22.839a.731.731 0 0 0 0 1.462h4.809a.718.718 0 0 0 .314-.073.716.716 0 0 0 .407-.555l.03-.017a.047.047 0 0 1-.024-.007c.003-.026.003-.052.003-.079a.724.724 0 0 0-.575-.71Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 20"
                  />
                  <path
                    d="M28.265 309.158c-.195.076-.406.119-.624.119h-4.799c-.218 0-.429-.043-.624-.12-2.69 2.13-5.742 6.178-6.148 9.475-.538 4.42 1.99 9.368 9.172 9.368s9.71-4.947 9.168-9.368c-.402-3.297-3.455-7.345-6.145-9.474Zm-3.383 13.554c-.31-.033-.6-.089-.852-.171-.409-.133-.759-.321-1.036-.563a2.28 2.28 0 0 1-.64-.87 2.864 2.864 0 0 1-.231-1.087l-.01-.305h.05l1.425.007.023.271c.02.222.08.42.172.576.095.162.217.3.373.406.155.106.333.189.54.242a2 2 0 0 0 .143.032l.008.003.008.001.033-.202v-.072l.01-1.677h-.01a8.683 8.683 0 0 1-1.079-.33 2.972 2.972 0 0 1-.785-.463 1.718 1.718 0 0 1-.492-.649c-.018-.042-.033-.087-.048-.131a1.943 1.943 0 0 1-.1-.521c-.002-.054-.005-.107-.004-.161 0-.337.073-.645.221-.926.142-.281.343-.526.598-.724.25-.195.55-.347.894-.457a3.42 3.42 0 0 1 .828-.142l.003-.252.02-.508.677.003-.007.767c.278.027.535.073.77.146.36.105.676.268.943.476.271.211.482.473.637.774.152.304.241.648.248 1.021l.007.308h-.05l-1.432-.01-.027-.261c-.04-.367-.196-.835-.956-.98-.045-.01-.085-.022-.135-.029v.002l-.008-.002v.172l-.01 1.462c.116.023.225.053.34.076.235.053.466.122.687.201.224.08.442.176.643.285.212.122.403.268.565.433.165.175.3.384.392.622.096.235.142.512.142.816 0 .36-.082.692-.234.99a2.292 2.292 0 0 1-.63.77 2.928 2.928 0 0 1-.928.492c-.31.103-.643.153-1.003.166v.75l-.7-.003.007-.774Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 19"
                  />
                  <path
                    d="M25.585 321.577v.008h.013c.159-.01.311-.026.443-.063.182-.046.341-.112.463-.198a.799.799 0 0 0 .268-.28.705.705 0 0 0 .093-.361.728.728 0 0 0-.073-.35.727.727 0 0 0-.268-.249 2.84 2.84 0 0 0-.618-.248 12.043 12.043 0 0 0-.249-.065c-.02-.007-.038-.01-.057-.017v.001l-.008-.001-.007 1.806v.017Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 18"
                  />
                  <path
                    d="M24.914 316.688v-.008c-.304.03-.543.102-.718.221-.208.143-.304.315-.304.55 0 .105.013.191.046.257a.44.44 0 0 0 .162.162c.1.067.248.133.434.189.105.03.23.066.37.102l.003-.046.003-.238.004-1.093v-.096Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 17"
                  />
                  <path
                    d="M23.061 306.872h4.365l1.475-2.553a.546.546 0 0 0-.3-.79 10.698 10.698 0 0 0-3.357-.529 10.7 10.7 0 0 0-3.357.53.546.546 0 0 0-.3.79l1.474 2.552Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 16"
                  />
                  <path
                    d="m35.926 309.57-.001-.002a5.719 5.719 0 0 0-1.412-1.551 5.712 5.712 0 0 0-.873-.552 5.861 5.861 0 0 0-1.3-.47 5.653 5.653 0 0 0-1.322-.155c-.65 0-1.294.118-1.908.336-.102.035-.205.067-.306.108l.003.003c-.003 0-.006.002-.008.003.239.22.414.508.495.834.002.01.006.018.007.028h.001l.002.007a4.737 4.737 0 0 1 3.36-.03 4.775 4.775 0 0 1 2.417 1.96v.002c.021.033.05.055.077.08l.014.017c.091.084.204.127.325.13h.011a.478.478 0 0 0 .128-.018l.006-.002c.042-.013.084-.022.123-.046a.496.496 0 0 0 .16-.682Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 15"
                  />
                  <path
                    d="M34.962 307.573a5.21 5.21 0 0 0 1.022-2.024v-.003a.494.494 0 0 0-.357-.597l-.004-.001c-.036-.01-.07-.004-.106-.005l-.016-.002h-.006a.472.472 0 0 0-.162.031.481.481 0 0 0-.13.071l-.004.003a.483.483 0 0 0-.177.262 4.197 4.197 0 0 1-.823 1.617c-.024.03-.044.06-.07.089h.002l-.005.007c.294.159.572.344.836.552Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 14"
                  />
                  <path
                    d="M29.758 309.6c.102 0 .202-.012.303-.016a7.415 7.415 0 0 0 1.763-.29 6.886 6.886 0 0 0 .805-.293c.09-.04.185-.071.274-.115l.052-.026.016-.003-.005-.003.01-.004a4.073 4.073 0 0 0-1.432-.496 6.31 6.31 0 0 1-2.123.244l-.179-.01c-.003.034-.007.063-.017.093a1.65 1.65 0 0 1-.34.74c-.032.038-.062.077-.098.112h.005l-.007.008c.325.04.649.06.973.06Z"
                    fill="#fff"
                    file-rule="evenodd"
                    data-name="パス 13"
                  />
                </g>
              </svg>
              ボーナス
            </span>
            {(currentUser && currentUser.user_role === 1) ? <span className="side-bar" onClick={redirectToUpDownLoad}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="21"
                viewBox="0 0 512 512">
                <path
                  d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"
                  fill="#fff"
                  file-rule="evenodd"
                  data-name="Icon awesome-long-arrow-alt-left"
                />
              </svg>
              データ更新
            </span>
              : <></>
            }
          </Drawer>
        </>
      )}
      <Link to={"/login"} onClick={() => dispatch(closeLogoutModal())} className="navbar-brand">
        <img src="/image/CasinoUnity.png" alt="" />
      </Link>

      {!!currentUser && isLogin && (
        <div className="navbar-nav ml-auto">
          <li
            className="nav-item d-flex"
            style={{
              marginRight: "21px",
              color: "#ffffff",
              fontSize: "10px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {currentUser.name}
          </li>
          <li className="nav-item" style={{ cursor: "pointer" }}>
            <img src="/image/logout_icon.svg" alt="" onClick={handleLogout} />
          </li>
        </div>
      )}
    </nav>
  );
};

export default Header;
