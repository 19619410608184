import {React,useEffect} from "react";
import { useSelector } from "react-redux";
import "../assets/style/Profile.scss";

const Profile = () => {
  const currentUser = useSelector(state => state.auth.user);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="profile-container">
      <header className="jumbotron">
        <h3>
          Profile
        </h3>
      </header>
      <p>
        <strong>Id:</strong> {currentUser?.id || 'null'}
      </p>
      <p>
        <strong>Nick name:</strong> {currentUser?.name || 'null'}
      </p>
      <p>
        <strong>Email:</strong> {currentUser?.email || 'null'}
      </p>
    </div>
  );
};

export default Profile;
