/* eslint-disable no-console */
import axios from "axios";
import service from "../services/auth.service";

const API_URL = process.env.REACT_APP_BASE_URL;
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common.Accept = "application/json";

axios.interceptors.request.use((request) => {
  const token = service.getTokenChain();
  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return request
});

export default axios;
