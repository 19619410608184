import React, { useEffect, useState } from "react";
import "../assets/style/Register.scss";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { register as registerAction } from "../store/auth/reducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "antd";
import Policy from "../assets/svg/cu_privacy_policy.pdf";
import TermsOfUse from "../assets/svg/cu_terms_of_use.pdf";

const nameError = "※有効なニックネームを入力してください。";
const emailError = "※有効なメールアドレスを入力してください。";
const passwordError = "※有効なパスワードを入力してください。";
const checkboxError = "※必須項目です。";

const schema = yup
  .object({
    name: yup.string(nameError).required(nameError),
    email: yup.string(emailError).email(emailError).required(emailError),
    password: yup
      .string(passwordError)
      .required(passwordError)
      .min(8, passwordError),
    passwordConfirm: yup
      .string(passwordError)
      .required(passwordError)
      .min(8, passwordError)
      .oneOf([yup.ref("password")], passwordError),
    chooseCb: yup.bool().oneOf([true], checkboxError),
    chooseCbTerms: yup.bool().oneOf([true], checkboxError),
  })
  .required();

function Register() {
  const [inputText, setInputText] = useState("");
  const [emailText, setEmailText] = useState("");
  const [passwordText, setPasswordText] = useState("");
  const [confirmPasswordText, setConfirmPasswordText] = useState("");

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { isLogin, errorCode, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (errorCode === "x001") {
      navigate("/verify/send");
    } else if (errorCode === "" && isLogin) {
      navigate("/");
    }
  }, [isLogin, errorCode, navigate]);

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    dispatch(registerAction(data));
  };

  const handleInputNameChange = (e) => {
    if (e.target.value.length > 200) {
      return false;
    } else {
      setInputText(e.target.value);
    }
  };
  const handleInputEmailChange = (e) => {
    if (e.target.value.length > 200) {
      return false;
    } else {
      setEmailText(e.target.value);
    }
  };
  const handleInputPassChange = (e) => {
    if (e.target.value.length > 200) {
      return false;
    } else {
      setPasswordText(e.target.value);
    }
  };
  const handleInputConfirmPassChange = (e) => {
    if (e.target.value.length > 200) {
      return false;
    } else {
      setConfirmPasswordText(e.target.value);
    }
  };

  return (
    <div className="register_container">
      <p className="register__slogan">CASINO UNITY</p>
      <label className="register__title">新規登録</label>
      <form
        className="flex-column"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="form-group form-register-input">
          <label className="label_type">ニックネーム</label>
          <input
            className={`form_input  ${
              !!errors.name ? "border_input_error" : ""
            }`}
            {...register("name")}
            value={inputText}
            onChange={handleInputNameChange}
          />
          <p className="register__error">{errors.name?.message}</p>
        </div>

        <div className="form-group form-register-input">
          <label className="label_type">メールアドレス</label>
          <input
            className={`form_input  ${
              !!errors.email ? "border_input_error" : ""
            }`}
            {...register("email")}
            value={emailText}
            onChange={handleInputEmailChange}
          />
          <p className="register__error">{errors.email?.message}</p>
        </div>
        <div className="form-group form-register-input">
          <label className="label_type">パスワード</label>
          <input
            className={`form_input  ${
              !!errors.password ? "border_input_error" : ""
            }`}
            type="password"
            {...register("password")}
            onChange={handleInputPassChange}
            value={passwordText}
          />
          <p className="register__error">{errors.password?.message}</p>
        </div>
        <div className="form-group form-register-input">
          <label className="label_type">パスワード（確認）</label>
          <input
            className={`form_input  ${
              !!errors.passwordConfirm ? "border_input_error" : ""
            }`}
            type="password"
            {...register("passwordConfirm")}
            onChange={handleInputConfirmPassChange}
            value={confirmPasswordText}
          />
          <p className="register__error">{errors.passwordConfirm?.message}</p>
        </div>
        <div className="form-group form-register-input" style={{marginBottom: '8px'}}>
          <div className="register__checkbox">
            <Controller
              name="chooseCb"
              control={control}
              defaultValue={false}
              render={({ field }) => {
                return (
                  <Checkbox
                    className={` ${
                      !!errors.chooseCb ? "border_input_error" : ""
                    }`}
                    onChange={field.onChange}
                    checked={!!field.value}
                  />
                );
              }}
            />
            <span className="register__checkbox_text">
              <label className="checkbox_link">
                <a href={Policy} target="_blank" rel="noreferrer">
                  プライバシーポリシー
                </a>
              </label>
              <label className="checkbox_default">に同意する</label>
            </span>
          </div>
          <p className="register__error policy">{errors.chooseCb?.message}</p>
        </div>
        <div className="form-group form-register-input">
          <div className="register__checkbox">
            <Controller
              name="chooseCbTerms"
              control={control}
              defaultValue={false}
              render={({ field }) => {
                return (
                  <Checkbox
                    className={` ${
                      !!errors.chooseCbTerms ? "border_input_error" : ""
                    }`}
                    onChange={field.onChange}
                    checked={!!field.value}
                  />
                );
              }}
            />
            <span className="register__checkbox_text">
              <label className="checkbox_link">
                <a href={TermsOfUse} target="_blank" rel="noreferrer">
                  利用規約
                </a>
              </label>
              <label className="checkbox_default">に同意する</label>
            </span>
          </div>
          <p className="register__error policy">{errors.chooseCbTerms?.message}</p>
        </div>
        {error?.register && (
          <span className="error-feedback">{error.register}</span>
        )}
        <button type="submit" className="btn_submit">
          会員登録
        </button>
      </form>
    </div>
  );
}

export default Register;
