import { React, useRef } from 'react'
import "../assets/style/Stk_003.scss";

function Stk003(props) {
  const data = props.data?.data
  const notice_copy = useRef(null)
  const modalRef = useRef(null)

  const formatConcurrency = (concurrency) => {
    return Number(concurrency)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }

  const handleCopyBtn = (e) => {
    var noticeTimeout
    clearTimeout(noticeTimeout)
    noticeTimeout = setTimeout(() => { notice_copy.current.style.display = "none" }, 3000);
    const parentNode = e.target.parentNode
    const text = parentNode.getElementsByTagName('label')[0].innerText
    copyToClipboard(text)
    notice_copy.current.style.display = "block"
  }

  const handleClose = () => {
    props.handleCloseStk003(false)
  }

  const handleStk003 = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose()
    }
  }

  return (
    <div className='stk003' onClick={handleStk003}>
      <div className="container_stk003" ref={modalRef}>
        <div className="container_stk003_content">
          <div className="usdt_address stk003_address ">
            <h1 className='address_title'>{data?.system_deposit_setting[0]}</h1>
            <div className='address'>
              <label>{data?.wallet_address}</label>
              <button onClick={handleCopyBtn}>コピー</button>
            </div>
          </div>
          <div className="usdt_network stk003_network">
            <label>ネットワーク</label>
            <label>{data?.wallet_network}</label>
          </div>
          <div className="busd_address stk003_address ">
            <h1 className='address_title'>{data?.system_deposit_setting[1]}</h1>
            <div className='address'>
              <label>{data?.wallet_address}</label>
              <button onClick={handleCopyBtn}>コピー</button>
            </div>
          </div>
          <div className="busd_network stk003_network">
            <label>ネットワーク</label>
            <label>{data?.wallet_network}</label>
          </div>
          <div className="usdc_address stk003_address ">
            <h1 className='address_title'>{data?.system_deposit_setting[2]}</h1>
            <div className='address'>
              <label>{data?.wallet_address}</label>
              <button onClick={handleCopyBtn}>コピー</button>
            </div>
          </div>
          <div className="usdc_network stk003_network">
            <label>ネットワーク</label>
            <label>{data?.wallet_network}</label>
          </div>
          <div className="deposit_amount">
            <div className="deposit_amount_minimum">
              <label className='deposit_amount_title'>最低入金額</label>
              <label>{`${formatConcurrency(data?.minumum_deposit_amount)} USDT/BUSD/USDC`}</label>
            </div>
            <div className="deposit_amount_maximum">
              <label className='deposit_amount_title'>最高入金額</label>
              <label>{`${formatConcurrency(data?.maximum_deposit_amount)} USDT/BUSD/USDC`}</label>
            </div>
          </div>
        </div>
        <div className="modal_caution">
          <div className="modal_caution_title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z" />
            </svg>
            <label>Caution</label>
          </div>
          <div className="modal_caution_content">
            <label>※最低入金額を下回る金額、また最高入金額を上回る</label>
            <label>入金を行った場合、ステーキングとして受理されません。</label>
            <label className='label_margin'>※入金が反映されるまで1週間ほどかかります。</label>
            <label>※複数のウォレットアドレスからの入金を行う際は、</label>
            <label>運営にお問い合わせください。</label>
          </div>
        </div>
        <div className="btn_close" onClick={handleClose}>
          <img src="./image/close(x) icon.svg" alt="" />
        </div>
        <div className="notice_copy" ref={notice_copy}>
          アドレスをコピーしました
        </div>
      </div>
    </div>
  )
}

export default Stk003


