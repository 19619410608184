import React from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import service from "../services/auth.service"

const RenderRoute = ({ component: Component, layout: Layout, isProtected, ...rest }) => {
  const isAuthentication = useSelector(state => state.auth.isLogin)

  return (
    isProtected && !isAuthentication && !service.getTokenChain() ? (
    <Navigate to='/login' replace={true}/> )
    : (
      Layout
      ? <Layout isProtected={isProtected}>
          <Component {...rest}/>
        </Layout>
      : <Component />
    )
  )
}

RenderRoute.propTypes = {
  component: PropTypes.any.isRequired,
  location: PropTypes.object,
};

export default RenderRoute
