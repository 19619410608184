import request from "../utils/request";
import Cookie from "js-cookie";

const register = async ({ name, email, password, passwordConfirm }) => {
  return request.post("/register", {
    name,
    email,
    password,
    password_confirmation: passwordConfirm,
  });
};

const login = async (email, password, remember = false) => {
  return request
    .post("/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data?.access_token) {
        sessionStorage.setItem("access_token", response.data.access_token);
        if (remember) {
          Cookie.set("access_token", response.data.access_token, {
            expires: 90,
          });
        }
      }

      return response.data;
    });
};

const refresh = async () => {
  return request.post("/refresh").then((response) => {
    if (response.data?.access_token) {
      sessionStorage.setItem("access_token", response.data.access_token);
      if (Cookie.get("access_token")) {
        Cookie.set("access_token", response.data.access_token, { expires: 90 });
      }
    }

    return response.data;
  });
};

const sendVerifyEmail = async (id) => {
  return request.post("/verify/resend", { id });
};

const confirmVerify = async (url) => {
  return request.post(url);
};

const getProfile = async () => {
  return request.get("/profile");
};

const logout = () => {
  Cookie.remove("access_token");
  sessionStorage.removeItem("access_token");
};

const getTokenChain = () => {
  const flashToken = sessionStorage.getItem("access_token");
  const sessionToken = Cookie.get("access_token");
  return flashToken || sessionToken;
};

const setAutoComplete = ({ email, password, autoFill }) => {
  if (autoFill) {
    localStorage.setItem(
      "autoFill",
      JSON.stringify({ email, password, autoFill })
    );
  } else {
    localStorage.removeItem("autoFill");
  }
};

const AuthService = {
  register,
  login,
  logout,
  getProfile,
  refresh,
  sendVerifyEmail,
  confirmVerify,
  getTokenChain,
  setAutoComplete,
};

export default AuthService;
