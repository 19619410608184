import request from "../utils/request";

const getStakingData = () => {
  return request.get('/staking');
};

const getStakingDetails = (gameType) => {
  return request.get(`/staking/get-system-setting/${gameType}`);
};

const getStakingWallet = (gameType) => {
  return request.get(`/staking/wallet-setting/${gameType}`);
};

const postStakingWallet = (data)=>{
    return request.post('/staking/report', 
    {
        deposit_amount: Number(data.content_deposit_amount),
        deposit_type: Number(data.radioValue),
        transaction_id: data.id_address,
        payment_date: data.date,
        payment_time: data.time,
        staking_log_game_type: data.gameTypeText
    })
}

const StalkingService = {
  getStakingData,
  getStakingDetails,
  getStakingWallet,
  postStakingWallet,
};

export default StalkingService;
