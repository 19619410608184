import DefaultLayout from "../layout";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Home from "../pages/Home";
import Profile from "../pages/Profile";
import Wallet from "../pages/Wallet";
import StakenAmountDetail from "../pages/StakenAmountDetail";
import Error404 from "../pages/Error404";
import AuthenticationEmail from "../pages/AuthenticationEmail";
import ConfirmAuthenEmail from "../pages/ConfirmAuthenEmail";
import Staking_002 from "../pages/Staking_002";
import Affiliate from "../pages/Affiliate";
import UpDownLoad from "../pages/UpDownLoad";

const routes = [
  {
    path: "/",
    exact: true,
    isProtected: true,
    layout: DefaultLayout,
    component: Home,
  },
  {
    path: "/login",
    exact: true,
    layout: DefaultLayout,
    component: Login,
  },
  {
    path: "/register",
    exact: true,
    layout: DefaultLayout,
    component: Register,
  },
  {
    path: "/profile",
    isProtected: true,
    exact: true,
    layout: DefaultLayout,
    component: Profile,
  },
  {
    path: "/verify/send",
    isProtected: false,
    exact: true,
    layout: DefaultLayout,
    component: AuthenticationEmail,
  },
  {
    path: "/verify-email",
    isProtected: false,
    exact: true,
    layout: DefaultLayout,
    component: ConfirmAuthenEmail,
  },
  {
    path: "/wallet",
    isProtected: true,
    exact: true,
    layout: DefaultLayout,
    component: Wallet,
  },
  {
    path: "/staken-amount",
    isProtected: true,
    exact: true,
    layout: DefaultLayout,
    component: StakenAmountDetail,
  },
  {
    path: "/staking",
    isProtected: true,
    exact: true,
    layout: DefaultLayout,
    component: Staking_002,
  },
  {
    path: "/affiliate",
    isProtected: true,
    exact: true,
    layout: DefaultLayout,
    component: Affiliate,
  },
  {
    path: "/update-data",
    isProtected: true,
    exact: true,
    layout: DefaultLayout,
    component: UpDownLoad,
  },
  {
    path: "*",
    exact: true,
    component: Error404,
  },
];

export default routes;
