import React from "react";
import "../assets/style/LogoutModal.scss";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { updateIsLogoutModal } from "../store/layout/reducer";
import { logout } from "../store/auth/reducer";

function LogoutModal() {
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.stopPropagation()
    dispatch(logout());
    dispatch(updateIsLogoutModal());
  };
  const handleCancel = (e) => {
    e.stopPropagation()
    dispatch(updateIsLogoutModal());
  };

  const handleModalClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
  };

  return (
    <div className="container__logout_modal" onClick={handleCancel}>
      <div className="container logout_modal" onClick={handleModalClick}>
        <div className="logout_modal__title">
          <p className="logout_modal__title_text">ログアウトしますか？</p>
        </div>
        <div className="logout_modal__btn">
          <button className="cancel" onClick={handleCancel}>
            キャンセル
          </button>
          <button className="logout" onClick={handleLogout}>
            ログアウト
          </button>
        </div>
      </div>
    </div>
  );
}

export default LogoutModal;
