import React, { useEffect, useState, useRef } from "react";
import "../assets/style/Affiliate.scss";
import { Progress, Steps } from 'antd';
import walletService from "../services/wallet.service";
import CommonLoader from "../components/loader";

const Affiliate = () => {
  const formatConcurrency = (concurrency) => {
    return Number(concurrency)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const formatConcurrencyNum = (num) => {
    return Number(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const { Step } = Steps;
  const [current, setCurrent] = useState([0]);
  const [content, setContent] = useState({});
  const [processPercent, setProcessPercent] = useState(0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(true);
  const notice_copy_invite = useRef(null);
  const noticeShow = () => {
    notice_copy_invite.current.style.display = "none"
  }

  useEffect(() => {
    walletService.getWalletData().then(
      (response) => {
        setContent(response.data);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);

  const StakingBonusText = () => {
    return (
      <div className="steps_staking_bonus">
        <img src='./image/gift.svg' alt="" />
        <label>Staking Bonus</label>
      </div>
    )
  }

  useEffect(() => {
    if (content?.profit) {
      var totalAmount = content?.profit?.total_deposit_amount + content?.profit?.invited_total_deposit_amount
      setTotal(totalAmount)
      setProcessPercent(totalAmount / content?.nextAwardAmount * 100)
    }
  }, [content])

  useEffect(() => {
    total > 10000 ? setCurrent([0, 1, 2, 3]) : total === 10000 ? setCurrent([0, 1, 2]) : (total > 3000 && total < 10000) ? setCurrent([0, 1]) : setCurrent([0])
  }, [total])

  function copyToClipboard(textToCopy) {
    if (navigator.clipboard && window.isSecureContext) {
      return navigator.clipboard.writeText(textToCopy);
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = textToCopy;
      textArea.style.position = "fixed";
      textArea.style.left = "-999999px";
      textArea.style.top = "-999999px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise((res, rej) => {
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  }

  const btn_invite = () => {
    walletService.getLinkInviteUser().then(
      (response) => {
        var noticeTimeout
        clearTimeout(noticeTimeout)
        noticeTimeout = setTimeout(noticeShow, 3000);
        copyToClipboard(response?.data)
        notice_copy_invite.current.style.display = "block"
      },
      () => { }
    )
  }

  return (
    <>
      {isLoading && <div className="loading-container"><CommonLoader isLoading={isLoading} /></div>}
      {!isLoading &&
        <div className="affiliate-container-body">
          <div className="button-group">
            <div className="row-col-name d-flex">
              <div
                className="col-name col-staking title_total_amount d-flex"
              >
                Total Amount
              </div>
            </div>
          </div>
          <div className="home__container affiliate-container">
            <div className="information__coin affiliate-information progress-information">
              <div className="row f-flex game-circle content_game_circle">
                <div className="col-5">
                  <div className="progress-group">
                    <Progress
                      type="circle"
                      percent={processPercent}
                      format={() =>
                        <div className="progress-info">
                          <img src="/image/money_bag.svg" alt="" />
                          <label className="total-amount">{formatConcurrencyNum(total)}</label>
                          <label className="next-reward">
                            {`Of ${formatConcurrencyNum(content?.nextAwardAmount === 0 ? total : content?.nextAwardAmount)}`}
                          </label>
                        </div>
                      } />
                  </div>
                </div>
                <div className="col-2"></div>
                <div className="col-5 col-amount-group">
                  <div className="amount-group">
                    <div className="amount-info">
                      <label className="title">Your Amount</label>
                      <label>
                        {formatConcurrency(content?.profit?.invited_total_deposit_amount) || 0} USDT
                      </label>
                    </div>
                    <div className="amount-info invited">
                      <label className="title">Invited Amount</label>
                      <label>
                        {formatConcurrency(content?.profit?.total_deposit_amount) || 0} USDT
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="information__coin affiliate-information">
              <label className="label_reward">
                {`NEXT REWARD @${formatConcurrencyNum(content?.nextAwardAmount === 0 ? total : content?.nextAwardAmount)} USDT`}
              </label>
            </div>
            <div className="information__coin affiliate-information">
              <div className="row f-flex game-circle content_game_step">
                <Steps current={current} direction="vertical">
                  <Step className={current.includes(0) && (current.length === 1 ? 'active last-active' : 'active')} title="Start Casino Unity" description={StakingBonusText()} />
                  <Step className={current.includes(1) && (current.length === 2 ? 'active last-active' : 'active')} title="3,000 USDT " description={(<div className="description_extra">Extra 100 USDT</div>)} />
                  <Step className={current.includes(2) && (current.length === 3 ? 'active last-active' : 'active')} title="10,000 USDT " description="Special Offer" />
                  <Step className={current.includes(3) && (current.length === 4 ? 'active last-active' : 'active')} title="10,000USDT Over?" description="Special Invitation?" />
                </Steps>
              </div>
            </div>
            <div className="information__coin affiliate-information invite_group">
              <div className="notice_copy_invite" ref={notice_copy_invite}>
                <p>案内文と招待リンクをコピーしました</p>
              </div>
              <button className="btn-invite" onClick={btn_invite}>
                <p>INVITE USER</p>
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Affiliate;
