import {
  configureStore
} from "@reduxjs/toolkit";
import auth from "./auth/reducer"
import layout from "./layout/reducer";

export default configureStore({
  reducer: {
    auth,
    layout,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})
