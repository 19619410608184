import { React, useEffect, useState, useRef } from 'react'
import stakingService from "../services/staking.service";
import "../assets/style/Stk_004.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const TextError1 = "※有効な入金額を入力してください。";
const TextError2 = "※有効な入金日時を入力してください。";
const TextError3 = "※有効なトランザクションIDを入力してください。";

const schema = yup.object({
    deposit: yup.string(TextError1).required(TextError1),
    id_address: yup.string().required(TextError3),
    dates: yup.date(TextError2).required(TextError2).nullable(TextError2),
    time: yup.string(TextError2).required(TextError2).nullable(TextError2),
}).required();

function Stk_004(props) {
    const gameType = props.gameType;
    const [isShowStkMain, setIsShowStkMain] = useState(true);
    const [isShowReport, setIsShowReport] = useState(false);
    const [depositAmountValue, setDepositAmountValue] = useState('');
    const [radioValue, setRadioValue] = useState(0);
    const [gameTypeText] = useState(gameType);
    const [withdrawAddress, setWithdrawAddress] = useState("");
    const [isTimePast, setIsTimePass] = useState(false);
    const [isIdAddress, setIsIdAddress] = useState(false);
    const [isSafariDate, setIsSafariDate] = useState(false);
    const [isSafariTime, setIsSafariTime] = useState(false);

    useEffect(() => {
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            setIsSafariDate(true)
            setIsSafariTime(true)
            document.querySelector('.content_select_time').value = 'HH:mm'
            document.querySelector('.content_select_date').valueAsDate = new Date();
        }
        else {
            setIsSafariDate(false)
            setIsSafariTime(false)
        }
    }, [])

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: yupResolver(schema),
    });

    // set no pass time
    useEffect(() => {
        var dtToday = new Date();
        var month = dtToday.getMonth() + 1;
        var day = dtToday.getDate();
        var year = dtToday.getFullYear();
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();
        var maxDate = year + '-' + month + '-' + day;
        document.querySelector('.content_select_date').setAttribute("min", maxDate)
    }, [])

    const onSubmit = (data) => {
        const content_deposit_amount = document.querySelector('.content_deposit_amount').value
        const date = document.querySelector('.content_select_date').value
        const time = document.querySelector('.content_select_time').value
        const id_address = document.querySelector('.id_address').value
        const param = {
            content_deposit_amount,
            date,
            time,
            id_address,
            radioValue,
            gameTypeText
        }
        stakingService.postStakingWallet(param)
            .then(response => {
                setIsShowStkMain(false)
                setIsShowReport(true)
            })
            .catch(err => {
                if (err.response.data?.errors?.payment_time) {
                    setIsTimePass(true)
                }
                else if (err.response.data?.errors?.transaction_id) {
                    setIsIdAddress(true)
                }
            });
    }

    const handleReport = () => {
        setIsShowReport(false)
        props.handleCloseStk004(false)
    }

    const handleRadioChange = (e) => {
        setRadioValue(e.target.value)
    }

    const modal_report = useRef(null)
    const modal_stk004 = useRef(null)
    const modal_input = useRef(null)

    const handleClickOutsideClose = (e) => {
        if (modal_report.current && !modal_report.current.contains(e.target)) {
            handleReport()
        }
        if (modal_stk004.current && !modal_stk004.current.contains(e.target)) {
            props.handleCloseStk004(false)
        }
    }

    const handleKeyDownDeposit = (e) => {
        if (e.keyCode === 189) {
            e.preventDefault();
        }
    }

    const handleDeposit = (e) => {
        const TCode = e.target.value
        const TCodeNoDot = TCode.replace('.', '')
        if (e.target.value === '.' || TCode > 999999999.99 || TCodeNoDot.length > 11 || e.target.value === '00') {
            return false
        }
        else if (TCode === '' || (/^[0-9]*(\.[0-9]{0,2})?$/.test(TCode))) {
            setDepositAmountValue(TCode.replace(/^\.+/gm, ''));
        } else {
            return false
        }
    }

    useEffect(() => {
        if (depositAmountValue.length > 2 && depositAmountValue.indexOf('0', 0) === 0 && depositAmountValue.indexOf('.', 0) !== 1) {
            return setDepositAmountValue(depositAmountValue.slice(1, depositAmountValue.length))
        }
    }, [depositAmountValue])

    const addressChangeHandler = (e) => {
        var TCode = e.target.value;
        if ((TCode === '' || (/^[a-zA-Z0-9]+$/.test(TCode))) && TCode.length < 81) {
            setWithdrawAddress(e.target.value.replace(/^\s+/gm, ''));
        }
        else {
            return false;
        }
        if (TCode.length < 40) {
            setIsIdAddress(true)
        }
        else {
            setIsIdAddress(false)
        }
    };

    const handleInputDate = () => {
        setIsSafariDate(false)
    }

    const handleInputTime = () => {
        setIsSafariTime(false)
    }

    return (
        <div className="stk004" onClick={handleClickOutsideClose} >
            {isShowStkMain &&
                <div className='container_stk004' ref={modal_stk004}>
                    <div className="stk004_main_content">
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            autoComplete="off"
                        >
                            <label>入金額</label>
                            <input
                                type="text"
                                className='content_deposit_amount content_input_text'
                                {...register("deposit")}
                                value={depositAmountValue}
                                onChange={handleDeposit}
                                onKeyDown={handleKeyDownDeposit}
                            />
                            <p className="text_err">{errors.deposit?.message}</p>
                            <div className="content_radio">
                                <div className="content_radio_item">
                                    <input type="radio" id="radio"
                                        className="money_type"
                                        name="money-type"
                                        value="0"
                                        checked={radioValue == 0}
                                        onChange={handleRadioChange}
                                    />
                                    <label className='content_label_input' htmlFor="radio">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                                        </svg>
                                    </label>
                                    <label className='label_text' htmlFor="radio">USDT</label>
                                </div>
                                <div className="content_radio_item">
                                    <input type="radio" id="radio2"
                                        className="money_type"
                                        name="money-type"
                                        value="1"
                                        checked={radioValue == 1}
                                        onChange={handleRadioChange}
                                    />
                                    <label className='content_label_input' htmlFor="radio2">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                                        </svg>
                                    </label>
                                    <label className='label_text' htmlFor="radio2">BUSD</label>
                                </div>
                                <div className="content_radio_item">
                                    <input type="radio" id="radio3"
                                        className="money_type"
                                        name="money-type"
                                        value="2"
                                        checked={radioValue == 2}
                                        onChange={handleRadioChange}
                                    />
                                    <label className='content_label_input' htmlFor="radio3">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M438.6 105.4C451.1 117.9 451.1 138.1 438.6 150.6L182.6 406.6C170.1 419.1 149.9 419.1 137.4 406.6L9.372 278.6C-3.124 266.1-3.124 245.9 9.372 233.4C21.87 220.9 42.13 220.9 54.63 233.4L159.1 338.7L393.4 105.4C405.9 92.88 426.1 92.88 438.6 105.4H438.6z" />
                                        </svg>
                                    </label>
                                    <label className='label_text' htmlFor="radio3">USDC</label>
                                </div>
                            </div>

                            <div className="content_select">
                                <label className="content_select_label">入金日時</label>
                                <input
                                    type="date"
                                    className={`content_select_date ${isSafariDate ? 'date_safari' : ''} `}
                                    {...register("dates")}
                                    onClick={handleInputDate}
                                />
                                <input
                                    type="time"
                                    className={`content_select_time ${isSafariTime ? 'time_safari' : ''} `}
                                    onClick={handleInputTime}
                                    {...register("time")}
                                />
                                {errors.date || errors.time ? (<p className="text_err">{errors.date?.message || errors.time?.message}</p>) : <></>}
                                {isTimePast && <p className='text_err'>支払い時間は現在よりも長くする必要があります</p>}
                            </div>

                            <div className="content_transactionId">
                                <label className="content_transaction_title">トランザクションID</label>
                                <input
                                    className='content_input_text id_address'
                                    value={withdrawAddress}
                                    {...register("id_address", {
                                        onChange: addressChangeHandler,
                                        ref: modal_input
                                    })}
                                />
                                {errors.id_address || isIdAddress ? <p className="text_err">※有効なトランザクションIDを入力してください。</p> : <></>}
                            </div>

                            <div className="content_btn">
                                <button type="submit" className='btn_deposit_report'>入金報告</button>
                                <button className='btn_cancel' onClick={() => props.handleCloseStk004(false)}>キャンセル</button>
                            </div>
                        </form>
                    </div>
                </div>
            }

            {isShowReport &&
                <div className="container_stk004_report container_stk004" ref={modal_report}>
                    <h1 className='container_stk004_report_title' >入金が報告されました。</h1>
                    <button onClick={handleReport} className='container_stk004_report_btn'>OK</button>
                </div>
            }
        </div>
    )
}

export default Stk_004
