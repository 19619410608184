import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import authService from "../services/auth.service";
import "../assets/style/authenEmail.scss";

function ConfirmAuthenEmail() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  let [query] = useSearchParams();

  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    (async () => {
      try {
        const url = query.get('queryURL').replace('http://', 'https://');
        await authService.confirmVerify(url);
      } catch (error) {
        setMessage(message.common.unknownError);
      }
    })()
  })

  return (
    <div className="authentication_email">
      <div className="authentication_email_title">
        <p className="authentication_email_title_text">CASINO UNITY</p>
      </div>
      <div className="authentication_email_notice">
        <label>{!message.length ? (
          <>
            <p style={{margin: '0px'}}>認証が有効化されました。 </p>
            <p style={{margin: '0px'}}>トップへ戻りログインしてください</p>
          </>
        ) : message}</label>
      </div>
      <div className="authentication_email_btn">
        <button onClick={handleClick}>TOPへ</button>
      </div>
    </div>
  );
}

export default ConfirmAuthenEmail;
