import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { refresh } from "../store/auth/reducer";
import service from "../services/auth.service";
import routes from "./routes";
import RenderRoute from "./renderRoute";
import CommonLoader from "../components/loader";

const AppRoutes = (history) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.auth.isLoading);

  useEffect(() => {
    if (service.getTokenChain()) {
      dispatch(refresh());
    }
  }, []);

  return (
    <>
      {<CommonLoader isLoading={isLoading} />}
      {!isLoading &&<Routes>
        {routes.map((route, i) => {
          return (
            <Route {...route} key={i} element={<RenderRoute {...route} />} />
          );
        })}
      </Routes>}
    </>
  );
};

export default AppRoutes;
