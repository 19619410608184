import { React, useState, useRef, useEffect } from 'react'
import stakingService from "../services/staking.service";
import { useLocation } from 'react-router-dom';
import "../assets/style/Stk_002.scss";
import Stk004 from './Staking_004';
import Stk003 from './Staking_003';
import CommonLoader from "../components/loader";

function Stk_002() {
  const location = useLocation();
  const [content, setContent] = useState([]);
  const [contentStakingWallet, setContentStakingWallet] = useState([])
  const [stakingType, setStakingType] = useState(0);
  const [isShowStk004, setIsShowStk004] = useState(false)
  const [isShowStk003, setIsShowStk003] = useState(false)
  const [enableBtnReport, setEnableBtnReport] = useState(false)
  const [estimatedValue, setEstimatedValue] = useState('0.00')
  const [takenProfitValue, setTakenProfitValue] = useState(0.00)
  const [pageTitle, setPageTitle] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [inputMoneyValue, setInputMoneyValue] = useState("");

  const btn_report = useRef(null)
  const profitValue = useRef(null)
  const inputMoneyProfit = useRef(null)

  const formatConcurrency = (concurrency) => {
    return Number(concurrency)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const formatConcurrencyNum = (num) => {
    return Number(num)
      .toFixed(2)
  };

  useEffect(() => {
    setEnableBtnReport(false);
    setIsLoading(true);
    window.scrollTo(0, 0)

    let staking_type_btn = document.querySelectorAll(".col-name")
    staking_type_btn[0].classList.add('active')
  }, [])

  useEffect(() => {
    setIsShowStk003(location?.state?.showUp)
    if (location.state != null) {
      setEnableBtnReport(true)
      setStakingType(location?.state?.type)
    }
  }, [location?.state?.showUp, location?.state?.type])

  const handleStakingAmount = (e) => {
    const TCode = e.target.value
    const TCodeNoDot = TCode.replace('.', '')
    if (e.target.value === '.' || TCodeNoDot.length > 11 || e.target.value === '00') {
      return false
    }
    else if (TCode === '' || (/^[0-9]*(\.[0-9]{0,2})?$/.test(TCode))) {
      setInputMoneyValue(TCode.replace(/^\.+/gm, ''));
      const getProfitValue = Number(profitValue.current.innerText)
      const takenProfit = e.target.value * getProfitValue / 100
      const estimatedAmountValue = Number(takenProfit) + Number(e.target.value)
      setTakenProfitValue(takenProfit)
      setEstimatedValue(estimatedAmountValue)
    }
    else {
      return false;
    }
  }

  useEffect(() => {
    if (inputMoneyValue.length > 2 && inputMoneyValue.indexOf('0', 0) === 0 && inputMoneyValue.indexOf('.', 0) !== 1) {
      return setInputMoneyValue(inputMoneyValue.slice(1, inputMoneyValue.length))
    }
  }, [inputMoneyValue])

  const handleCloseStk004 = (isShow) => {
    setIsShowStk004(isShow)
  }

  const btnShowStk004 = () => {
    setIsShowStk004(true)
  }

  const handleShowStk003 = (isShow) => {
    setIsShowStk003(isShow)
    setEnableBtnReport(true)
    const btnReport = btn_report.current
    btnReport.addEventListener("click", btnShowStk004)
  }

  useEffect(() => {
    setIsLoading(true);
    stakingService.getStakingWallet(stakingType).then(
      (response) => {
        setContentStakingWallet(response.data);
        window.scrollTo(0, 0);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );

    stakingService.getStakingDetails(stakingType).then(
      (response) => {
        setContent(response.data);
        window.scrollTo(0, 0);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );

    setTakenProfitValue('');
    switch (stakingType) {
      case 0:
        setPageTitle('BJ Staking');
        break;
      case 1:
        setPageTitle('RL Staking');
        break;
      case 2:
        setPageTitle('BC Staking');
        break;
      default:
        setPageTitle('');
        break;
    }
  }, [stakingType]);

  const clearInputValue = () => {
    setEnableBtnReport(false)
    setInputMoneyValue('')
    document.querySelector('.text_profits').innerHTML = '+ 0'
    setEstimatedValue('')
  }

  return (
    <div className="container stk002-container-body">
      <div className="button-group">
        <div className="row-col-name d-flex">
          <div className={`col-name ${stakingType == 0 ? "active col-staking" : ""
            } d-flex`}
            onClick={(e) => { setStakingType(0); clearInputValue() }}
          >
            BJ
          </div>
          <hr />
          <div className={`col-name ${stakingType == 1 ? "active col-staking" : ""
            } d-flex`}
            onClick={(e) => { setStakingType(1); clearInputValue() }}
          >
            RL
          </div>
          <hr />
          <div className={`col-name ${stakingType == 2 ? "active col-staking" : ""
            } d-flex`}
            onClick={(e) => { setStakingType(2); clearInputValue() }}
          >
            BC
          </div>
        </div>
      </div>
      {isLoading && <div className="loading-container"><CommonLoader isLoading={isLoading} /></div>}
      {!isLoading &&
        <div className="content-main-stk002">
          <h1 className='d-flex'>{pageTitle}</h1>
          <table className='table table-stk002'>
            <tbody>
              <tr>
                <th>Minimum</th>
                <th>
                  {formatConcurrency(
                    content?.data?.minumum_deposit_amount || 0
                  )}
                </th>
                <th>[USDT]</th>
              </tr>
              <tr>
                <th>Maximum</th>
                <th>
                  {formatConcurrency(
                    content?.data?.maximum_deposit_amount || 0
                  )}
                </th>
                <th>[USDT]</th>
              </tr>
              <tr>
                <th>Expected Profit</th>
                <th ref={profitValue}>
                  {formatConcurrency(
                    content?.data?.expected_profit * 100 || 0
                  )}
                </th>
                <th>[%/Year]</th>
              </tr>
              <tr>
                <th>Staking Term</th>
                <th>
                  <p>1Year</p>
                  {content?.data?.renewal_month ? (
                    <p className='text_color_orange'>
                      (1Month Renewal)
                    </p>
                  ) : ''}

                </th>
              </tr>
              <tr className='table_tr_input'>
                <th>Staking Amount</th>
                <th>
                  <input type='text'
                    className='table_input'
                    onChange={(e) => handleStakingAmount(e)}
                    ref={inputMoneyProfit}
                    value={inputMoneyValue} />

                </th>
                <th>[USDT]</th>
              </tr>
              <tr className='table_tr_input'>
                <th>Estimated Amount</th>
                <th>
                  <input readOnly className='table_input' value={formatConcurrencyNum(estimatedValue)} />
                  <p className='text_color_orange text_profits'>+ {formatConcurrencyNum(takenProfitValue)}</p>
                </th>
                <th>[USDT]</th>
              </tr>
            </tbody>
          </table>
          <div className="d-flex content_btn_info_confirm">
            <button onClick={handleShowStk003} >{
              `${stakingType === 0 ? 'BJ'
                : stakingType === 1 ? 'RL'
                  : stakingType === 2 ? 'BC'
                    : ''
              } 
              Stakingの入金先を表示`}
            </button>
          </div>
          <div className="modal_caution">
            <div className="modal_caution_title">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM232 152C232 138.8 242.8 128 256 128s24 10.75 24 24v128c0 13.25-10.75 24-24 24S232 293.3 232 280V152zM256 400c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 385.9 273.4 400 256 400z" />
              </svg>
              <label>Caution</label>
            </div>
            <div className="modal_caution_content">
              <ul>
                <li>
                  <p>入金先は変更される場合があります。</p>
                </li>
                <li>
                  <p>必ずアドレスを都度ご確認ください。</p>
                </li>
                <li>
                  <p>ステーキング期間は出金が出来ません。</p>
                </li>
                <li>
                  <p>報酬は収益期待値を下回る場合がございます。</p>
                </li>
                <li>
                  <p>それをご理解頂いた上でご入金へお進みください。 </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex content_btn_confirm">
            <button className={`${enableBtnReport ? 'enable_btn_report' : 'btn_report'}`} ref={btn_report}>
              入金完了報告
            </button>
          </div>
        </div>
      }
      {
        isShowStk004 && <Stk004 handleCloseStk004={handleCloseStk004} gameType={stakingType} />
      }
      {
        isShowStk003 && <Stk003 handleCloseStk003={handleShowStk003} data={contentStakingWallet} />
      }
    </div>
  )
}

export default Stk_002
