import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import store from "./store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

const mountApp = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter>
    <App {...{ store }} />
  </BrowserRouter>,
  mountApp
);

serviceWorker.unregister();
