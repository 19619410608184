import request from "../utils/request";

const getWalletData = () => {
  return request.get('/wallet');
};

const getStakingDetails = (gameType) => {
  return request.get('/wallet/stakings', {params: {game_type: gameType}});
};

const createWithdrawRequest = (data) => {
  return request.post('/wallet/withdraw', data);
};

const getLinkInviteUser=()=>{
  return request.get('/wallet/get-invite-link')
}
const WalletService = {
  getWalletData,
  getStakingDetails,
  createWithdrawRequest,
  getLinkInviteUser,
};

export default WalletService;
