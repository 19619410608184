import { React, useState, useRef, useEffect } from 'react'
import '../assets/style/UpDownLoad.scss'
import upDownLoadService from '../services/upDownLoad.service';
import FileUpLoader from '../components/file-uploader'
import WalletService from '../services/wallet.service';

function UpDownLoad() {
  const [isModal, setIsModal] = useState();
  const [typeFile, setTypeFile] = useState(null)
  const [isCloseReportMail, setIsCloseReportMal] = useState(false)
  const [isShowModalReportMail, setIsShowModalReportMail] = useState(false)

  const handleSubmissionDeposit = () => {
    setIsModal(true)
    setTypeFile(0)
  }

  const handleSubmissionWithdrawal = () => {
    setIsModal(true)
    setTypeFile(1)
  }

  const checkInternetConnection= () =>{
    WalletService.getStakingDetails(1).then(
      () => {},
      (error) => {
        alert('You are offline')
      }
    );
  }
  const handleDownLoadDepositReport = () => {
    upDownLoadService.downloadDepositReport()
      .then(response => {
        window.location.assign(response.data)
      })
      .catch(err => {
        console.log(err);
      })
      checkInternetConnection()
  }

  const handleDownLoadWithdrawal = () => {
    upDownLoadService.downloadWithdrawal()
      .then(response => {
        window.location.assign(response.data)
      })
      .catch(err => {
        console.log(err);
      })
      checkInternetConnection()
  }

  const closeModal = () => {
    setIsModal(false)
  }

  const isShowModalReport = (state) => {
    setIsShowModalReportMail(state)
  }

  const handleClickOutSide = (e) => {
    let modal = document.querySelector('.modal_upload_file')
    if (modal && !modal.contains(e.target)) {
      closeModal()
    }
  }

  const handleClickOutsideReportMail = (e) => {
    let modal_report_mail = document.querySelector('.modal_success_mail')
    if (modal_report_mail && !modal_report_mail.contains(e.target)) {
      isShowModalReport(false)
    }
  }
  return (
    <div className='update-data-container'>
      <div className="button_group">
        <div className="button_upload button_group_item">
          {
            isModal && <div className="modal_uploads" onClick={handleClickOutSide}>
              <FileUpLoader closeModalFnc={closeModal} typeFile={typeFile} closeReportMail={isCloseReportMail} isShowModalReport={isShowModalReport} />
            </div>
          }
          {isShowModalReportMail &&
            <div className="modal_mail_report" onClick={handleClickOutsideReportMail}>
              <div className="modal_success_mail">
                <p className='modal_success_title'>Upload success!</p>
                <div className="modal_success_mail_content">
                  <p>An email will be sent to you when the import is finished, check your mail to see the results.</p>
                </div>
                <div className="button_group_modal">
                  <button onClick={() => setIsShowModalReportMail(false)}>OK</button>
                </div>
              </div>
            </div>
          }
          <div className='table-update-data'>
            <p className='title_upload_file'>System Deposit Report</p>
            <div className='group-btn-update'>
              <button className='btn_upload' onClick={handleSubmissionDeposit}>
                Upload CSV
              </button>
              <button type='button' className='btn_download' onClick={handleDownLoadDepositReport}>
                Download CSV
              </button>
            </div>
          </div>
          <div className='table-update-data'>
            <p className='title_upload_file'>System Withdrawal Request</p>
            <div className='group-btn-update'>
              <button className='btn_upload' onClick={handleSubmissionWithdrawal}>
                Upload CSV
              </button>
              <button type='button' className='btn_download' onClick={handleDownLoadWithdrawal}>
                Download CSV
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpDownLoad
