import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useSelector } from "react-redux";
import { selectIsLogoutModal } from "../store/layout/reducer";
import LogoutModal from "../components/LogoutModal";

const Layout = ({ children, isProtected }) => {
  const isShowLogout = useSelector(selectIsLogoutModal);

  return (
    <div className="body">
      <Header isProtected={isProtected} />
      <div className="d-flex justify-content-center position-relative">
        <div className={`container mt-3 ${isShowLogout ? 'blur' : ''}`}>{children}</div>
        {isShowLogout ? <LogoutModal /> : <></>}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
