import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import walletService from "../services/wallet.service";
import "../assets/style/Wallet.scss";
import CommonLoader from "../components/loader";

const Wallet = () => {
  let navigate = useNavigate();

  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  
  const formatConcurrency = (concurrency) => {
    return Number(concurrency)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    window.scrollTo(0, 0)
    walletService.getWalletData().then(
      (response) => {
        setContent(response.data);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  }, []);

  const navigateToAfl = () => {
    navigate("/affiliate");
  }
  const navigateToSkt002 = () => {
    navigate("/staking");
  }
  
  return (
    <>
      {isLoading && <div className="loading-container"><CommonLoader isLoading={isLoading} /></div>}
      {!isLoading &&
        <div className="wallet-container-body">
          <div className="button-group">
            <div className="row-col-name d-flex">
              <div
                className="col-name col-staking d-flex"
                onClick={navigateToSkt002}
              >
                Staking
              </div>
              <hr />
              <div className="col-name col-bonus d-flex"
                onClick={navigateToAfl}
              >Bonus</div>
            </div>
          </div>
          <div className="home__container wallet-container">
            <label className="information__text">
              Total: {formatConcurrency(content?.profit?.total_balance || 0)} [USDT]
            </label>
            <div className="information__coin wallet-information staking-information">
              <div className="row">
                <label className="wallet-type">Staking</label>
              </div>
              <div className="row f-flex game-circle">
                <div className="col-3 col-name">
                  <div className="circle__bj_coin coin_circle m-auto">
                    <span>BJ</span>
                  </div>
                  <label>
                    {formatConcurrency(
                      JSON.parse(content?.profit?.game_balance || null)?.[0] || 0
                    )}
                  </label>
                  <label className="label-profit">
                    {formatConcurrency(content?.profit?.bj_profit || 0)}
                  </label>
                </div>
                <div className="col-3 col-name">
                  <div className="circle__rl_coin coin_circle m-auto">
                    <span>RL</span>
                  </div>
                  <label>
                    {formatConcurrency(
                      JSON.parse(content?.profit?.game_balance || null)?.[1] || 0
                    )}
                  </label>
                  <label className="label-profit">
                    {formatConcurrency(content?.profit?.rl_profit || 0)}
                  </label>
                </div>
                <div className="col-3 col-name">
                  <div className="circle__bc_coin coin_circle m-auto">
                    <span>BC</span>
                  </div>
                  <label>
                    {formatConcurrency(
                      JSON.parse(content?.profit?.game_balance || null)?.[2] || 0
                    )}
                  </label>
                  <label className="label-profit">
                    {formatConcurrency(content?.profit?.bc_profit || 0)}
                  </label>
                </div>
              </div>
              <div className="row f-flex row-title">
                <div className="col-1">
                  <label>Amount</label>
                  <label className="label-profit">Profit</label>
                </div>
              </div>
            </div>
            <div className="information__coin wallet-information bonus-information">
              <div className="row">
                <label className="wallet-type">Bonus</label>
              </div>
              <div className="row f-flex game-circle">
                <div className="col-5">
                  <div className="circle__bj_coin coin_circle m-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44"
                      height="30"
                      viewBox="105 427 44 30"
                    >
                      <path
                        d="M118.2 442c4.256 0 7.7-3.355 7.7-7.5s-3.444-7.5-7.7-7.5-7.7 3.355-7.7 7.5 3.444 7.5 7.7 7.5Zm5.28 2.143h-.57a11.068 11.068 0 0 1-4.71 1.071c-1.691 0-3.273-.401-4.71-1.071h-.57c-4.373 0-7.92 3.455-7.92 7.714v1.929c0 1.774 1.478 3.214 3.3 3.214h19.8c1.822 0 3.3-1.44 3.3-3.214v-1.929c0-4.259-3.547-7.714-7.92-7.714ZM138 442c3.644 0 6.6-2.88 6.6-6.429 0-3.549-2.956-6.428-6.6-6.428-3.644 0-6.6 2.88-6.6 6.428 0 3.55 2.956 6.429 6.6 6.429Zm3.3 2.143h-.261c-.956.321-1.967.536-3.039.536s-2.083-.215-3.039-.536h-.261c-1.403 0-2.695.395-3.83 1.031 1.678 1.761 2.73 4.098 2.73 6.683v2.572c0 .147-.034.288-.041.428H145.7c1.822 0 3.3-1.44 3.3-3.214 0-4.145-3.444-7.5-7.7-7.5Z"
                        fill="#ffd96f"
                        data-name="Icon awesome-user-friends"
                      />
                    </svg>
                  </div>
                  <label>User Invitation</label>
                  <label className="label-profit">{content?.profit?.invited_num || 0}</label>
                </div>
                <div className="col-5">
                  <div className="circle__rl_coin coin_circle m-auto">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="30"
                      viewBox="232 427 24 30"
                    >
                      <g data-name="ドル袋の無料アイコン素材">
                        <path
                          d="M246.128 432.617a.726.726 0 0 0-.184-.024H240.232a.868.868 0 0 0 0 1.737H245.944c.133 0 .26-.032.373-.087a.85.85 0 0 0 .483-.66l.035-.02a.056.056 0 0 1-.027-.007c.004-.032.004-.063.004-.094a.86.86 0 0 0-.684-.845Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 20"
                        />
                        <path
                          d="M246.73 434.619c-.234.09-.487.141-.75.141h-5.763c-.261 0-.515-.051-.749-.141-3.23 2.53-6.897 7.337-7.384 11.253-.646 5.252 2.39 11.128 11.015 11.128 8.626 0 11.662-5.876 11.012-11.128-.484-3.916-4.15-8.724-7.38-11.253Zm-4.063 16.1a5.053 5.053 0 0 1-1.023-.204 3.546 3.546 0 0 1-1.244-.668 2.71 2.71 0 0 1-.77-1.033 3.37 3.37 0 0 1-.277-1.292l-.012-.362h.06l1.712.008.028.323c.024.263.095.498.206.683.115.192.262.357.448.483s.4.224.65.287c.053.014.11.027.17.039.004 0 .007.001.01.003v-.001l.01.002.04-.24v-.086l.012-1.991h-.012c-.5-.122-.932-.252-1.296-.393a3.58 3.58 0 0 1-.944-.55 2.043 2.043 0 0 1-.59-.77c-.022-.05-.04-.103-.058-.156a2.285 2.285 0 0 1-.119-.62c-.003-.063-.007-.126-.006-.19 0-.401.088-.766.266-1.1.17-.334.412-.625.718-.86a3.452 3.452 0 0 1 1.074-.542c.305-.095.638-.15.995-.17l.002-.3.025-.603.813.004-.008.911c.333.032.642.087.924.173.432.126.812.319 1.133.566.325.251.579.562.765.92.183.36.29.769.298 1.213l.008.365h-.06l-1.72-.012-.032-.31c-.048-.436-.236-.992-1.148-1.165-.054-.01-.102-.025-.162-.033v.002l-.01-.002v.204l-.012 1.736c.139.028.27.063.408.09.282.064.56.146.825.24.27.094.531.208.773.338.254.145.484.318.678.515.198.208.36.455.471.738.115.279.17.609.17.97 0 .428-.098.821-.28 1.175a2.729 2.729 0 0 1-.758.915 3.53 3.53 0 0 1-1.114.585c-.372.122-.772.18-1.205.197v.891l-.84-.004.008-.919Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 19"
                        />
                        <path
                          d="M243.51 449.286v.01h.016c.188-.012.369-.031.526-.074.216-.055.405-.134.55-.236a.95.95 0 0 0 .318-.334.836.836 0 0 0 .11-.428.865.865 0 0 0-.086-.416.864.864 0 0 0-.319-.295 3.372 3.372 0 0 0-.734-.295l-.295-.077c-.023-.008-.046-.013-.068-.02v.002l-.01-.003-.008 2.146v.02Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 18"
                        />
                        <path
                          d="M242.69 443.436v-.01c-.362.036-.645.122-.853.263-.247.17-.361.374-.361.652 0 .126.015.228.055.307.031.067.098.13.192.192.118.079.295.158.515.224.125.036.275.079.44.122l.004-.055.003-.283.004-1.298.001-.114Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 17"
                        />
                        <path
                          d="M240.495 431.6h5.185l1.751-3.033a.648.648 0 0 0-.357-.939 12.707 12.707 0 0 0-3.987-.628c-1.406 0-2.75.22-3.987.628a.649.649 0 0 0-.357.94l1.752 3.032Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 16"
                        />
                        <path
                          d="m255.912 434.858-.001-.002a6.791 6.791 0 0 0-1.678-1.842 6.783 6.783 0 0 0-1.037-.656 6.963 6.963 0 0 0-1.543-.558 6.714 6.714 0 0 0-1.571-.185c-.773 0-1.537.14-2.266.4-.121.041-.244.08-.364.127l.004.004-.01.003a2 2 0 0 1 .588.992l.01.033.003.009a5.626 5.626 0 0 1 3.99-.035 5.671 5.671 0 0 1 2.871 2.33l.001-.001c.024.04.06.065.09.096l.018.02c.108.099.242.15.385.153l.01.001h.004a.567.567 0 0 0 .152-.022l.007-.002c.05-.015.1-.026.146-.055a.59.59 0 0 0 .191-.81Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 15"
                        />
                        <path
                          d="M254.768 432.432a6.187 6.187 0 0 0 1.213-2.404l.001-.003a.586.586 0 0 0-.425-.71l-.005-.001c-.042-.011-.084-.005-.126-.006-.006 0-.012-.003-.019-.003l-.007.001a.56.56 0 0 0-.193.037.574.574 0 0 0-.154.084l-.004.003a.574.574 0 0 0-.21.312 4.984 4.984 0 0 1-.978 1.92c-.029.035-.053.072-.083.106h.002l-.006.008c.35.189.68.409.994.656Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 14"
                        />
                        <path
                          d="M248.539 434.909c.121 0 .24-.015.36-.02a8.848 8.848 0 0 0 1.996-.316 8.175 8.175 0 0 0 1.055-.377c.107-.047.22-.084.324-.136l.062-.03.02-.004-.006-.003.01-.006a4.839 4.839 0 0 0-1.7-.589 7.497 7.497 0 0 1-2.522.29l-.212-.011c-.004.039-.008.074-.02.11-.059.329-.202.628-.403.879-.038.045-.075.091-.118.132l.006.001-.007.01c.385.046.77.07 1.155.07Z"
                          fill="#ffd96f"
                          fillRule="evenodd"
                          data-name="パス 13"
                        />
                      </g>
                    </svg>
                  </div>
                  <label>Total Amount</label>
                  <label className="label-profit">
                    {formatConcurrency(content?.profit?.bonus_total_amount || 0)}
                  </label>
                  <label className="label-reward">Next Reward + {formatConcurrency(content?.nextTotalAmount || 0)}</label>
                </div>
              </div>
              <hr />
              <div className="row f-flex game-circle">
                <div className="col-5">
                  <label>Invited Amount</label>
                  <label className="label-profit">{formatConcurrency(content?.profit?.invited_total_deposit_amount || 0)}</label>
                </div>
                <div className="col-5">
                  <label>Your Amount</label>
                  <label className="label-profit">{formatConcurrency(content?.profit?.total_deposit_amount || 0)}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Wallet;
